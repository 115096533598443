import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loading from '../components/Loading';
// Vistas Front end
const Login = lazy(() => import('../views/Login'));
const Home = lazy(() => import('../views/Home'));
const VideoApi = lazy(() => import('../views/VideoApi'));
const SeccionVideos = lazy(() => import('../views/SeccionVideos'));
const VideoPlayer = lazy(() => import('../views/SeccionVideos/VideoPlayer'));
const Comunidad = lazy(() => import('../views/Comunidad'));
const AR = lazy(() => import('../views/RealidadAumentada'));
const Pasos = lazy(() => import('../views/PasosASeguir'));
const CuentaRegresiva = lazy(() => import('../views/CuentaRegresiva'));
const Certificados = lazy(() => import('../views/Certificados'));
const InteraccionesLive = lazy(() => import('../views/InteraccionesLive'));
const Perfil = lazy(() => import('../views/Perfil'));
const ArchivoEnv = lazy(() => import('../views/ArchivoEnv'));

// ADMIN
const InteraccionesLiveAdmin = lazy(() => import('../views/Adminstration/InteraccionesLiveAdmin'));


export default function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>

          <Route exact path="/" element={<Login />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/video-api" element={<VideoApi />} />
          <Route exact path="/seccion-videos" element={<SeccionVideos />} />
          <Route exact path="/video/:id" element={<VideoPlayer />} />
          <Route exact path="/comunidad" element={<Comunidad />} />
          <Route exact path="/ar" element={<AR />} />
          <Route exact path="/steps" element={<Pasos />} />
          <Route exact path="/cuenta-regresiva" element={<CuentaRegresiva />} />
          <Route exact path="/certificados" element={<Certificados />} />
          <Route exact path="/interacciones-live" element={<InteraccionesLive />} />
          <Route exact path="/perfil" element={<Perfil />} />
          <Route exact path="/env" element={<ArchivoEnv />} />

          {/* Admin */}
          <Route exact path="/admin/interacciones-live" element={<InteraccionesLiveAdmin />} />
          
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
