import i18next from 'i18next';
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18next.use(HttpApi).use(LanguageDetector).use(initReactI18next).init({
	fallbackLng: "es-ES",
	debug:true,
	interpolation: {
		escapeValue: false, // not needed for react as it escapes by default
	},
});

export default i18next;
